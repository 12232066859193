@mixin base($border-color, $background-color)
  @apply border rounded-lg px-4 py-5
  border-color: $border-color
  background-color: $background-color

@mixin info()
  &-info
    @apply gap-4

@mixin closeIcon()
  &-close
    @apply ml-auto self-start

@mixin text($title-color, $description-color)
  &-title
    @apply mb-1
    color: $title-color
  &-description
    color: $description-color

@mixin buttons()
  &-buttons
    @apply gap-2 mt-4

  

lib-banner.danger
  .component-banner
    @include base($border-color: #B82F2F, $background-color: #FEEFEC)
    @include text($title-color: #B82F2F, $description-color: #B82F2F)
    @include info()
    @include closeIcon()      
    @include buttons()
    
lib-banner.warning
  .component-banner
    @include base($border-color: #BB6D1F, $background-color: #FFF5EB)
    @include text($title-color: #BB6D1F, $description-color: #BB6D1F)
    @include info()
    @include closeIcon()      
    @include buttons()

lib-banner.info
  .component-banner
    @include base($border-color: #2F5057, $background-color: #E8F4F6)
    @include text($title-color: #2F5057, $description-color: #2F5057)
    @include info()
    @include closeIcon()      
    @include buttons() 