@import breakpoints

@mixin mixin-base($font-family, $line-height, $font-weight, $m-font-size, $t-font-size, $font-size)
  font-family: $font-family
  font-size: $font-size
  font-weight: $font-weight
  line-height: $line-height
  margin: 0
  padding: 0
  @media screen and (max-width: $tablet-screen)
    font-size: $t-font-size
  @media screen and (max-width: $mobile-screen)
    font-size: $m-font-size

/* HEADER */
h1, .header-1
  @include mixin-base(Buenos Aires, 120%, 700, 2.626rem, 3.438rem, 4.188rem)
h2, .header-2
  @include mixin-base(Buenos Aires, 120%, 700, 2.062rem, 2.5rem, 3rem)
h3, .header-3
  @include mixin-base(Buenos Aires, 120%, 700, 1.500rem, 1.688rem, 2.062rem)

/* PARAGRAPH */
.paragraph-1
  @include mixin-base(Montserrat, 160%, 500, 1.125rem, 1.125rem, 1.125rem)
.paragraph-2
  @include mixin-base(Montserrat, 160%, 500, 1rem, 1rem, 1rem)
.paragraph-3
  @include mixin-base(Montserrat, 160%, 500, 0.875rem, 0.875rem, 0.875rem)

/* CAPTION */

.caption-1
  @include mixin-base(Montserrat, 130%, 500, 1.312rem, 1.812rem, 1.938rem)
.caption-2
  @include mixin-base(Montserrat, 130%, 500, 1.125rem , 1.125rem, 1.375rem)
.caption-3
  @include mixin-base(Montserrat, 130%, 500, 0.938rem, 1rem, 1.125rem)
.caption-4
  @include mixin-base(Montserrat, 130%, 500, 0.875rem, 0.875rem, 0.875rem)
.caption-5
  @include mixin-base(Montserrat, 130%, 500, 0.750rem, 0.750rem, 0.750rem)

/* LABEL */
.label-1
  @include mixin-base(Buenos Aires, 120%, 400, 1.125rem, 1.125rem, 1.125rem)
.label-2
  @include mixin-base(Montserrat, 120%, 500, 1rem, 1rem, 1rem)
.label-3
  @include mixin-base(Montserrat, 120%, 500, 0.875rem, 0.875rem, 0.875rem)
.label-4
  @include mixin-base(Montserrat, 120%, 500, 0.750rem, 0.750rem, 0.750rem)

/* TITLE */
.title-1
  @include mixin-base(Montserrat, 130%, 500, 1.562rem, 1.938rem, 2.062rem)
.title-2
  @include mixin-base(Montserrat, 130%, 500, 1.312rem, 1.312rem, 1.562rem)
.title-3
  @include mixin-base(Montserrat, 130%, 500, 1.125rem, 1.125rem, 1.125rem)
.title-4
  @include mixin-base(Montserrat, 130%, 500, 1rem, 1rem, 1rem)

.underline
  text-decoration: underline !important
.semi-bold
  font-weight: 600 !important
.bold
  font-weight: 700 !important
