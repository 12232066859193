@use '@angular/material' as mat
@include mat.radio-density(-1)
@import 'vars'

.mdc-radio__background
  .mdc-radio__outer-circle
    border-color: #0000008a!important

.objectif
  .mat-mdc-radio-group
    .mat-mdc-radio-checked
      .mdc-radio__background
        .mdc-radio__inner-circle,
        .mdc-radio__outer-circle
          border-color: $nutrition!important
    .mat-mdc-radio-button
      @apply w-[100px]
    .mdc-form-field
      @apply flex-col whitespace-pre-wrap
      label
        @apply mt-[.75rem] font-medium text-sm leading-3
        font-family: 'Montserrat', sans-serif

.monitoring-container
  .mat-mdc-radio-group
    .mat-mdc-radio-checked
      .mdc-radio__background
        .mdc-radio__inner-circle,
        .mdc-radio__outer-circle
          border-color: $nutrition!important
    .mat-mdc-radio-button
      @apply md:w-[210px]
      .mdc-form-field
        @apply md:flex-col
      label
        @apply md:text-lg md:leading-6 font-medium pl-0 md:mt-[.75rem]
        font-family: 'Montserrat', sans-serif

.radio-form
  .mdc-form-field,
  .mat-mdc-radio-button
    @apply w-full
  label
    @apply w-full whitespace-normal
  .mat-mdc-radio-checked
    .mdc-radio__background
      .mdc-radio__inner-circle,
      .mdc-radio__outer-circle
        border-color: $intellect!important