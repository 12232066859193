.mat-h1, .mat-headline, .mat-typography h1
  font-weight: 700

body, h1, h2, h3, h4, p, div, .mat-drawer-container, .mat-body p, .mat-body-1 p, .mat-typography p
  color: $navy

body
  margin: 0
  -webkit-text-size-adjust: 100%
  -webkit-tap-highlight-color: rgba(#000, 0)

textarea
  font-family: inherit
  font-size: 100%
  line-height: 1.15
  margin: 0
  &:focus
    outline: none
    font-size: 100%

.mat-drawer-container
  background: #fff

.mat-tooltip
  font-size: 14px !important

.chat-showcase p
  margin-bottom: 0 !important

.glass
  background: rgba(255, 255, 255, .76)
  backdrop-filter: blur(33px)
.meeting.glass
  background: rgba(255, 255, 255, .8)
  backdrop-filter: blur(16px)
.advisor.glass
  @include mq(portrait)
    background: rgba(255, 255, 255, .8)
    backdrop-filter: blur(16px)

.article-bg
  position: relative
  &::before
    content: ''
    width: 0
    height: 0
    border-left: 50px solid transparent
    border-right: 10px solid transparent
    border-bottom: 35px solid #5e7d6c
    position: absolute
    top: -1.75rem
    left: 14px

.red-text
  color: #ff664f

.hideScroll
  -ms-overflow-style: none /* Internet Explorer 10+ */
  scrollbar-width: none /* Firefox */

.hideScroll::-webkit-scrollbar
  display: none

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
  display: none

//.mat-mdc-menu-panel, .mat-sidenav
//  a:not(.lu-btn, .white-link), button:not(.lu-btn, .white-link)
//    @apply text-inherit

.luci-box-radius
  border-radius: 2.5rem

mat-header-cell, mat-cell
  font-family: Buenos Aires
  font-size: 16px
