.checkbox-component

  &-checkmark
    @apply border-2 border-green-500 rounded-[5px]

  &-checkmark--checked
    background-image: url('../../../assets/img/checkmark.svg')
    @apply bg-green-500 bg-center bg-no-repeat

  &:focus-within
    .checkbox-component-checkmark
      @apply outline outline-1
