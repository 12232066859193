
.mat-mdc-dialog-container
  border-radius: 26px
  .mdc-dialog__container
    .mat-mdc-dialog-surface
      border-radius: 26px

.dialog-fullwidth > .mat-mdc-dialog-container
  min-height: 300px
  max-height: 90vh
  height: auto
  width: 100vw
  margin: 0 -1.5rem
  overflow-y: auto
  overflow-x: hidden
  @include mq(portrait)
    max-height: 85vh
    max-width: 800px

.mdc-dialog .mdc-dialog__content
  padding: 0 !important

.dialog-overflow-visible
  .mdc-dialog__content
    overflow: visible

.contact-coach-dialog
  .mat-mdc-dialog-container
    max-width: 644px

.dialog
  @apply backdrop-brightness-50

.warning-dialog
  @extend .dialog
  @apply backdrop-blur-sm
