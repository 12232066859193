@import 'vars'

.mat-button, .mat-icon-button, .mat-stroked-button
  border-radius: 24px !important
  &.mat-primary
    color: #fff !important

.btn-see-all
  height: 115px
  width: 280px
