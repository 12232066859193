button
  @apply border-[2.25px]
  @apply disabled:cursor-default disabled:opacity-50

button.primary
  @apply bg-blue-1500 border-blue-1500 text-white
  &:enabled
    @apply hover:bg-blue-1600 hover:border-blue-1600
    @apply active:bg-blue-1600 active:border-blue-1600
  &.inverted
    @apply border-white text-blue-1500 bg-white
    &:enabled
      @apply hover:bg-blue-1100 hover:border-blue-1100 hover:text-blue-1500
      @apply active:bg-blue-1100 active:border-blue-1100 active:text-blue-1500
  &.danger
    @apply bg-red-1500 border-red-1500 text-white
    &:enabled
      @apply hover:bg-red-1600 hover:border-red-1600 hover:text-white
      @apply active:bg-red-1600 active:border-red-1600 active:text-white
  &.warning
    @apply bg-orange-1500 border-orange-1500 text-white
    &:enabled
      @apply hover:bg-orange-1600 hover:border-orange-1600 hover:text-white
      @apply active:bg-orange-1600 active:border-orange-1600 active:text-white
  &.success
    @apply bg-green-1500 border-green-1500 text-white
    &:enabled
      @apply hover:bg-green-1600 hover:border-green-1600 hover:text-white
      @apply active:bg-green-1600 active:border-green-1600 active:text-white
  &.info
    @apply bg-blue-1500 border-blue-1500 text-white
    &:enabled
      @apply hover:bg-blue-1600 hover:border-blue-1600 hover:text-white
      @apply active:bg-blue-1700 active:border-blue-1700 active:text-white

button.secondary
  @apply border-blue-1500 text-blue-1500
  &:enabled
    @apply hover:border-blue-1600 hover:text-blue-1600
    @apply active:border-blue-1600 active:text-blue-1600
  &.inverted
    @apply border-white bg-transparent text-white
    &:enabled
      @apply hover:border-blue-1100 hover:text-blue-1100
      @apply active:border-blue-1100 active:text-blue-1100
  &.danger
    @apply bg-transparent border-red-1500 text-red-1500
    &:enabled
      @apply hover:bg-transparent hover:border-red-1600 hover:text-red-1600
      @apply active:bg-transparent hover:border-red-1600 hover:text-red-1600
  &.warning
    @apply bg-transparent border-orange-1500 text-orange-1500
    &:enabled
      @apply hover:bg-transparent hover:border-orange-1600 hover:text-orange-1600
      @apply active:bg-transparent active:border-orange-1600 active:text-orange-1600
  &.success
    @apply bg-transparent border-green-1500 text-green-1500
    &:enabled
      @apply hover:bg-transparent hover:border-green-1600 hover:text-green-1600
      @apply active:bg-transparent active:border-green-1600 active:text-green-1600
  &.info
    @apply bg-blue-1050 border-blue-1050 text-blue-1500
    &:enabled
      @apply hover:bg-blue-1050 hover:border-blue-1050 hover:text-blue-1600
      @apply active:bg-blue-1050 active:border-blue-1050 active:text-blue-1700

button.tertiary
  @apply bg-transparent text-blue-1500 border-0
  &:enabled
    @apply hover:text-blue-1600
    @apply active:text-blue-1600
  &.inverted
    @apply bg-transparent text-white border-transparent
    &:enabled
      @apply hover:bg-transparent hover:border-transparent hover:text-blue-1100
      @apply active:bg-transparent active:border-transparent active:text-blue-1100
  &.danger
    @apply bg-transparent text-red-1500 border-red-1500
    &:enabled
      @apply hover:text-red-1600 border-red-1600
      @apply active:text-red-1600 border-red-1600
  &.warning
    @apply bg-transparent text-orange-1500 border-orange-1500
    &:enabled
      @apply hover:text-orange-1600 hover:border-orange-1600
      @apply active:text-orange-1600 active:border-orange-1600
  &.success
    @apply bg-transparent text-green-1500 border-green-1500
    &:enabled
      @apply hover:text-green-1600 hover:border-green-1600
      @apply active:text-green-1600 active:border-green-1600
  &.info
    @apply bg-transparent text-blue-1500 border-blue-1500
    &:enabled
      @apply hover:text-blue-1600 hover:border-blue-1600
      @apply active:text-blue-1700 active:border-blue-1700

button.rounded
  @apply rounded-[100px]

button.rectangle
  @apply rounded-md

button.icon-left
  @apply pl-5 pr-7

button.icon-right
  @apply pl-7 pr-5

button.icon-only
  @apply p-4

button.large
  @apply font-buenos text-label-1 font-bold py-[14px] px-[26px]
  &.icon-only
    &::before, &::after
      @apply w-8 h-8
  &.icon-left, &.icon-right
    &::before, &::after
      @apply w-6 h-6
  &.icon-left
    @apply pl-5 pr-[26px]
  &.icon-right
    @apply pr-5 pl-[26px]
  &.icon-only
    @apply p-4

button.medium
  @apply font-sans text-label-2 font-bold py-3 px-6
  &.icon-left, &.icon-right, &.icon-only
    &::before, &::after
      @apply w-6 h-6
  &.icon-left
    @apply pl-[18px] pr-6
  &.icon-right
    @apply pr-[18px] pl-6
  &.icon-only
    @apply p-3

button.small
  @apply font-sans text-label-3 font-semibold py-2 px-[22px]
  &.icon-left, &.icon-right, &.icon-only
    &::before, &::after
      @apply w-3 h-3
  &.icon-left
    @apply pl-4 pr-[22px]
  &.icon-right
    @apply pr-4 pl-[22px]
  &.icon-only
    @apply p-2
