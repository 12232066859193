button
  @extend .label-1
  @extend .bold
  @apply px-7 py-3 border-[3px]
  @apply disabled:cursor-default disabled:opacity-50

button.primary
  @apply bg-orange-500 border-orange-500 text-white
  &:enabled
    @apply hover:bg-[#E85C47] hover:border-[#E85C47]
    @apply active:bg-[#C9513F] active:border-[#C9513F]

button.secondary
  @apply bg-green-500 border-green-500 text-white
  &:enabled
    @apply hover:bg-[#516A5C] hover:border-[#516A5C]
    @apply active:bg-[#445A4E] active:border-[#445A4E]

button.tertiary
  @apply bg-transparent text-green-500 border-green-500
  &:enabled
    @apply hover:text-green-1000 hover:border-green-1000
    @apply active:text-white active:border-green-1000 active:bg-green-1000
  @apply disabled:border-grey-1003

  &.inverted
    @apply bg-transparent text-white border-white
    &:enabled
      @apply hover:bg-white hover:border-white hover:text-green-1000
      @apply active:bg-green-1000 active:border-green-1000 active:text-white

button.rounded
  @apply rounded-[100px]

button.rectangle
  @apply rounded-md

button.icon-left
  @apply pl-5 pr-7

button.icon-right
  @apply pl-7 pr-5

button.icon-only
  @apply px-4 py-4
