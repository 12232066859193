a, a.mat-button
  text-decoration: none
  color: $blue
  &:hover
    color: $navy
  &.disabled
    opacity: .4
    pointer-events: none

.profile-bilan-menu
  a.active
    font-weight: bold
    border-bottom: 3px solid $blue

.chatter
  a
    color: $orange
    &:hover
      text-decoration: underline
      opacity: 0.9

.signup
  .noWhiteText
    span, a, p
      color: $navy !important

  .white-in-mobile
    a, p
      text-decoration: underline
      color: #fff
      @include mq(landscape)
        color: $navy

    span
      color: #fff
      @include mq(landscape)
        color: $navy
    a span
      @include mq(landscape)
        color: $navy