// fix for angular material v15.
// conflict with tailwind.
// see: angular/components#26102 .
.mdc-notched-outline__notch
  border-right: none !important

.mat-mdc-text-field-wrapper
  background: white

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing
  border: 2px $navy solid

.mdc-text-field, .mdc-text-field__input
  color: $navy !important

mat-form-field.mat-mdc-form-field
  --mat-mdc-form-field-floating-label-scale: 0.9






.customClassPartnersSelect
  .mat-mdc-option
      @apply uppercase p-1 text-center
      > span
        @apply w-full
      .mat-pseudo-checkbox
        @apply hidden
.twoLettersOnly
  @apply w-9
  .language-switch-form
    .mat-mdc-form-field-subscript-wrapper
      @apply hidden
    .mat-mdc-select-value
      @apply uppercase font-bold text-base font-buenos
    .mat-icon
      @apply hidden
    .mat-form-field
      @apply w-full max-w-full
    .mat-mdc-text-field-wrapper
      @apply border-0
      padding: 0!important
.language-switch-form
  .mat-mdc-text-field-wrapper
    background: transparent
    border: 2px solid #5e7d6c
    border-radius: 44px
    padding: 8px 12px !important
  .mdc-notched-outline__leading, .mdc-notched-outline__trailing
    border: none !important
  .mat-mdc-form-field-infix
    padding-top: 0 !important
    padding-bottom: 0 !important
    min-height: auto !important

.trending-serch-form
  .mat-mdc-text-field-wrapper
    border-radius: 44px
  .mdc-notched-outline__leading, .mdc-notched-outline__trailing
    border: none !important
  .mat-mdc-form-field-infix
    display: flex