.mat-mdc-menu-panel.no-box-shadow
  box-shadow: none
  background-color: transparent
.mat-mdc-menu-panel
  background: #fff
.mat-mdc-menu-item:hover
  background: rgba(0,0,0,.04)

.user-menu
  .mat-mdc-menu-item.active
    color: $blue
  .mat-mdc-menu-item:hover:not([disabled]), .mat-mdc-menu-item.cdk-program-focused:not([disabled]), .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]), .mat-mdc-menu-item-highlighted:not([disabled])
    background: transparentize($lightblue, .6)
    color: $blue
  .mat-mdc-menu-content
    span, button, p, a
      color: $navy

.organize
  .mat-mdc-menu-content:not(:empty)
    padding-top: 0 !important
    padding-bottom: 0 !important