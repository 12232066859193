@use '@angular/material' as mat

$matRed: mat.define-palette(mat.$red-palette)
$matGreen: mat.define-palette(mat.$green-palette)

.mat-mdc-snack-bar-container
  &.snackbar-error-wrapper
    .mdc-snackbar__surface
      border-radius: 0
      background: mat.get-color-from-palette($matRed, 50)
      color: mat.get-color-from-palette($matRed, 500)
      border-left: 3px solid mat.get-color-from-palette($matRed, 500)
  &.snackbar-success-wrapper
    .mdc-snackbar__surface
      border-radius: 0
      background: mat.get-color-from-palette($matGreen, 50)
      color: mat.get-color-from-palette($matGreen, 500)
      border-left: 3px solid mat.get-color-from-palette($matGreen, 500)
