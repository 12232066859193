.mdc-tooltip__surface
  color: #fff!important
  background: rgba(97,97,97, .9)!important
  border-radius: 4px!important
  font-size: 14px !important
  line-height: 1rem!important
  margin: .75rem!important
  max-width: 250px!important
  padding: 10px 1rem!important
  overflow: hidden
  text-overflow: ellipsis

.mat-mdc-tooltip
  font-size: 14px !important
