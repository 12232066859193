@mixin radioComponent()
    @apply py-4 px-3 rounded-lg w-full border border-transparent

@mixin radioOuterCheck()
  @apply border-2 rounded-full w-5 h-5 flex justify-center items-center

@mixin radioInnerCheck()
  @apply rounded-full w-[10px] h-[10px]

@mixin radioDisabled()
  @apply opacity-70

@mixin radioFocus()
  .default-radio-component-outer-check
    @apply outline outline-1   



lib-input-default-radio.default
  .default-radio-component
    @include radioComponent()
    
    &--disabled
      @include radioDisabled()

    &:focus-within
      @include radioFocus()
  

lib-input-default-radio.inverted
  .default-radio-component
    @include radioComponent()
      
    &-label
      @apply text-white

    &--checked
      .default-radio-component-label
        @apply text-blue-500

    &--disabled
      @include radioDisabled()
      
    &:focus-within
      @include radioFocus()


lib-radio-indicator.default
  .radio-indicator
    &-outer-check
      @include radioOuterCheck()
      @apply border-grey-1100
      &--checked
        @apply border-green-500

    &-inner-check
      &--checked
        @include radioInnerCheck()
        @apply bg-green-500
  
lib-radio-indicator.inverted
  .radio-indicator
    &-outer-check
      @include radioOuterCheck()
      @apply border-white
      &--checked
        @apply border-green-500
        
    &-inner-check
      &--checked
        @include radioInnerCheck()
        @apply bg-green-500


lib-radio-with-text
  @apply w-[360px]
  lib-selection-box
    .selection-box-component
      @apply rounded-lg border border-grey-1100

  .radio-with-text-component
    @apply px-4 py-3

    &--disabled
      @include radioDisabled()

    &-description
      @apply mt-1 #{!important}