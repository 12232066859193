@use '@angular/material' as mat
@include mat.slide-toggle-density(-1)

.mat-mdc-slide-toggle
  @apply w-[36px]
  .mdc-form-field
    @apply w-full
  .mdc-switch
    width: 100%!important
  .mdc-switch__track
    height: 18px!important
    border-radius: 15px!important
  .mdc-switch__shadow
    display: none!important
  .mdc-switch .mdc-switch__handle::before
    height: 14px!important
    width: 14px!important
    top: 3px!important
    left: 3px!important
    background: #fff!important
  .mdc-switch.mdc-switch--unselected:enabled,
  .mdc-switch.mdc-switch--selected:enabled
    .mdc-switch__handle::after
      height: 14px!important
      width: 14px!important
      top: 3px!important
      left: 3px!important
      background: #fff!important
  .mdc-switch.mdc-switch--selected:enabled
    .mdc-switch__icon
      height: 10px!important
      width: 10px!important
      display: none!important

.organize
  .mat-mdc-slide-toggle
    width: 100%!important
    .mdc-form-field
      @apply py-3 px-8 flex items-center
    .mdc-switch
     @apply max-w-[36px]
    label
      @apply pl-3 h-auto text-sm
      color: $navy!important
.addNoteSlideToggle
  .mat-mdc-slide-toggle
    width: auto!important
    .mdc-form-field
      width: auto!important
      .mdc-switch
        @apply mr-1 max-w-[36px]
    label
      @apply whitespace-nowrap text-ellipsis
      color: $navy