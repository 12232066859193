@import 'vars'

.tabs-nav
  .mat-mdc-tab-nav-panel
    @apply flex
  .mat-mdc-tab-header
    border-bottom: 1px solid rgba(0,0,0,.12)
    color: #000000de
  .mat-mdc-tab-link
    @apply text-blue-500
    &:hover
      @apply text-green-500
    &.mdc-tab-indicator--active
      .mdc-tab-indicator
        @apply bottom-0 h-[4px] top-auto bg-green-500 rounded-2xl
        .mdc-tab-indicator__content--underline
          @apply hidden
