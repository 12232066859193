@import tailwindcss/base
@import tailwindcss/components
@import tailwindcss/utilities

@import vars
@import theme

@import mixins/import
@import modules/import
@import components/import
@import theme/import
@import tailwind/style

@import defaults

@import ~quill/dist/quill.core.css
@import ~quill/dist/quill.bubble.css
@import ~quill/dist/quill.snow.css
@import ~quill-emoji/dist/quill-emoji.css
@import swiper/scss
@import swiper/scss/navigation
@import swiper/scss/pagination
@import swiper/scss/effect-fade
@import swiper/scss/effect-cards

@import animations/import

.ql-container, .ql-toolbar.ql-snow
  font-family: inherit

.ql-editor
  background: #fff
  max-height: 200px
  word-break: break-word

.ql-active, .ql-active .ql-stroke
  color: $intellect !important
  stroke: $intellect !important

.emoji-mart
  box-shadow: 0 4px 12px rgba(#000, .12)

.textarea-emoji-control
  display: none
  @include mq(landscape)
    display: initial
    cursor: pointer
