@import 'vars'
.coach-table
  .mat-mdc-row
    background: #fff
    cursor: pointer
    transition: background .15s
    position: relative
    padding: 8px 0
    &:hover
      background-color:  lighten($lightgreen, 10%) !important
    &:nth-of-type(2n+2)
      background: #f1f1f1
    &:first-of-type
      border-top-left-radius: .25rem
      border-top-right-radius: .25rem
    &:last-of-type
      border-bottom-left-radius: .25rem
      border-bottom-right-radius: .25rem
    .mat-mdc-cell
      border: none
  .mat-mdc-header-row
    background-color: #f1f1f1
