$navy: #2f5057
$blue: #668875
$green: #00b7b2
// #4fc5b5
$orange: #f09f54
$max-width: 960px

$sport: #2F5057
$lightblue: transparentize($blue, .35)
$grey: #d6d6d6
$lightgreen: #A3B7AC
$green-bg: #f0f0f0

$intellect: #ff664f
$physic: #2f5057
$nutrition: #668875

$high: $blue
$medium: $orange
$low: $intellect

$white: #fff !default
$gray-200: #f0f0f0 !default
$gray-400: #d6d6d6 !default
$gray-600: #bbb !default
$gray-700: #999 !default
$gray-900: #212529 !default
$yellow: #f3f7a2 !default
$red: #dc3545 !default
$black: #000 !default

$font-size-sm: .875rem !default
$font-size-lg: 1.25rem !default

$primary: $physic !default
$secondary: $intellect !default
$tertiary: $nutrition !default

$theme-colors: ( 'primary': $primary, 'secondary': $secondary, 'tertiary': $tertiary, 'yellow': $yellow, 'gray-200': $gray-200, 'gray-400': $gray-400, 'gray-600': $gray-600,'gray-700': $gray-700, 'gray-900': $gray-900, 'danger': $red, 'light': $white, 'dark': $black )
$realgreen: #60A880
$reallightgreen: #E2F1E8
$lightred: #FEEFEC
