.lu-notif
  height: 20px
  width: 20px
  line-height: 20px
  font-weight: bold
  font-size: 0.875rem
  text-align: center
  border-radius: 50px
  color: #fff !important
  background: #FF5353
