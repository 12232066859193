.mat-mdc-checkbox
  @apply text-sm
  & + label
    white-space: break-spaces
  &.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background
    background: $blue
  .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark
    color: $white!important
.profilComp
  .mat-mdc-checkbox + label
    white-space: initial
// action plan
.task
  .mat-mdc-checkbox
    transform: scale(1.15)
    @apply mr-5 -top-2
    & + label
      white-space: break-spaces
  .mat-optgroup-label
    @apply font-black
    background: $grey
  .custom-frame
    .mdc-checkbox__background
      @apply rounded-full

.actionPlanV2
  .custom-frame
    .mat-mdc-checkbox + label
        @apply text-base
    .mdc-checkbox__background
      @apply rounded-full
// assign resource
.ressource-list-wrapper
  .mat-mdc-checkbox
    .mdc-checkbox__background
      @apply rounded-md

//signup
.signup
  .mat-mdc-checkbox + label
    @apply text-lg whitespace-normal
    .noGlass, .glass
      .form-row-actions
        .mdc-checkbox__background
          border-color: $navy

//form
.checkbox-form,
.img-form
  .mdc-form-field,
  .mdc-form-field label
    @apply w-full
  .mat-mdc-checkbox
    color: $intellect
    & + label
      @apply whitespace-normal flex-1
      width: calc( 100% - 10px)