.field-required::after
      content: "*"
      color: #da2e2e
      @apply pl-1

.hide-form-required-indicator
  .field-required::after
    display: none

lib-input-field
  .input-component
    .assistance-text
      @apply text-grey-1100

    .input-element
      @apply bg-white border border-grey-1500 rounded-md

      &:focus
        @apply border-green-500 outline-0

      &--disabled
        @apply bg-grey-100 border-grey-1500
      
      &--error
        @apply border-red-1500

    lib-validation-indicator
      span
        @apply font-bold
      img
        @apply h-[18px] w-[18px]

lib-select
  .select-component
    lib-validation-indicator
      span
        @apply font-bold
      img
        @apply h-[18px] w-[18px]

    .helpText
      @apply text-grey-1100
      
    ng-select.custom
      .ng-value-label,.ng-option-label
        @apply text-green-1000 font-sans font-medium

      .ng-option
        @apply text-green-1000

      .ng-option.ng-option-marked, .ng-option.ng-option-selected
        @apply bg-grey-1001

      &.error>.ng-select-container
        @apply border-red-1500
      
      &.ng-select-focused:not(.ng-select-opened)>.ng-select-container
        @apply border-2 border-green-500 shadow-none
