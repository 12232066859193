.rounded_green_toggle
  .mat-button-toggle-group
    @apply grid grid-cols-2 items-start gap-x-3 gap-y-4 border-0 bg-grey-200 rounded-none shadow-none

  .mat-button-toggle
    @apply border-2 bg-transparent border-green-500 rounded-full text-green-500 hover:bg-green-500 hover:text-white transition duration-300 focus:ring-4

  .mat-button-toggle-checked
    @apply text-white bg-green-500 hover:border-green-700 hover:bg-green-700


.checheck_green_toggle
  .mat-button-toggle:not(.deselected)
      button[aria-pressed="true"]
        background: $realgreen
        color: #fff