.catSelect
  &.mdc-menu-surface.mat-mdc-select-panel
    @apply p-0
  .mat-mdc-optgroup-label
    @apply font-bold
  .mat-mdc-option
    padding-top: .75rem !important
    padding-bottom: .75rem !important
    height: auto !important
    font-size: 1rem !important

.customClassSelectResources.cdk-overlay-pane:has(.mat-mdc-select-panel)
  min-width: fit-content !important

.coachResourceTable
  .trending-search-form
    @apply mb-0
  .src-assign-table
    .mat-sort-header-content
      color: #fff !important
  .mat-mdc-form-field
    .mat-mdc-form-field-subscript-wrapper
      @apply hidden
  .coach-table
    .mat-mdc-row
      padding: 0
      background-color: #fff
      border-bottom: solid 1px #0000001f
      &:hover
        background-color: transparent !important
    .mat-mdc-header-row
      min-height: 3rem
    .mat-mdc-header-cell
      @apply font-bold uppercase bg-green-500 text-white text-sm py-2
    .mat-mdc-cell
      @apply py-2
      p
        @apply mb-0
  .lu-btn.flex
    @apply py-1 px-4 my-1.5
  .search
    @apply border-2 border-green-500