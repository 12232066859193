.lu-btn
  // Hard coded padding because impossible to make a button with 42px height with tailwind
  padding-top: .4375rem
  padding-bottom: .4375rem
  @apply px-6 border-2 border-transparent focus:ring-4 rounded-full disabled:opacity-50 disabled:cursor-default transition duration-200 text-center cursor-pointer
  &-blue
    @apply bg-blue-500 text-white hover:bg-blue-700 focus:bg-blue-700 focus:ring-blue-200 disabled:bg-blue-600
  &-green
    @apply bg-green-500 text-white hover:bg-green-700 focus:bg-green-700 focus:ring-green-200 disabled:bg-green-600
  &-orange
    @apply bg-orange-500 text-white hover:bg-orange-700 focus:bg-orange-700 focus:ring-orange-200 disabled:bg-orange-600
  &-red
    @apply bg-red-500 text-white hover:bg-red-700 focus:bg-red-700 focus:ring-red-200 disabled:bg-red-600
  &-white
    // TODO : Fix text color
    @apply bg-white focus:ring-white focus:ring-opacity-75 disabled:opacity-50
  &-green-secondary
    @apply bg-white text-green-700 hover:bg-green-700 hover:text-white focus:bg-green-700 focus:text-white
  &-green-white
    @apply bg-white text-blue-500 focus:bg-blue-500 focus:text-white hover:bg-blue-500 hover:text-white
  &-border
    &-blue
      @apply text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white focus:border-blue-700 focus:bg-blue-700 focus:text-white focus:ring-blue-200
    &-green
      @apply text-green-500 border-green-500 hover:bg-green-500 hover:text-white focus:border-green-700 focus:bg-green-700 focus:text-white focus:ring-green-200
    &-orange
      @apply text-orange-500 border-orange-500 hover:bg-orange-500 hover:text-white focus:border-orange-700 focus:bg-orange-700 focus:text-white focus:ring-orange-200
    &-red
      @apply text-red-500 border-red-500 hover:bg-red-500 hover:text-white focus:border-red-700 focus:bg-red-700 focus:text-white focus:ring-red-200
    &-grey
      @apply text-green-700 border-grey-700 hover:opacity-70 focus:ring-gray-300
    &-white
      // TODO : Fix text color
      @apply border-white text-white focus:ring-white focus:ring-opacity-75 hover:bg-white focus:bg-white hover:text-black focus:text-black
    &-grey
      @apply border-grey-1003 focus:border-transparent hover:border-transparent focus:ring-white
    &-green-secondary
      @apply border-grey-1003 border-[3px] border-solid hover:border-green-700 focus:border-green-700
.lu-btn-round-icon
  @apply rounded-full grid place-items-center
  &-orange
    @apply bg-orange-500 w-10 h-10
    .mat-icon
      @apply text-white
  &-green
    @apply bg-green-500 w-10 h-10
    .mat-icon
      @apply text-white

.lu-btn-arrow
  @apply rounded-full grid place-items-center border-2 border-grey-400 bg-white bg-opacity-80 w-12 h-12 lg:w-16 lg:h-16
  .mat-icon
    @apply lg:transform lg:scale-150 w-9 h-9 text-4xl
